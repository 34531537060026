import { getWordCategory,postWordCategory } from '@/api/reservationsystemappointshop'
import { Message } from 'element-ui'

const state = {
   wordcategorylist: []
}

const mutations = {
    SET_WORD_CATEGORY: (state, wordcategorylist) => {
        state.wordcategorylist = wordcategorylist
    }
}

const actions = {
    // 得知所有常用子句分類
    getWordCategory({ commit }, page) {
        return new Promise((resolve, reject) => {
            getWordCategory(page).then(response => {
                // console.log("test", response)
                commit('SET_WORD_CATEGORY', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有常用子句
    insert_update_word_category({ commit }, data){
        return new Promise((resolve, reject) => {
            postWordCategory(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
