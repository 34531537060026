import router, { resetRouter } from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import permission from './directive/permission'
//websocket
import Pusher from 'pusher-js'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)
  console.log("1223",to.path)
  console.log("to",to)
  // determine whether the user has logged in
  const hasToken = getToken()
  console.log(hasToken)
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    }
    // else if (to.path == '/permission/role') {
    //   next(`/401`)
    //   NProgress.done()
    // }
    else {
      // determine whether the user has obtained his permission roles through getInfo
      await store.dispatch('user/getInfo')
      // 取合作夥伴config檔
      await store.dispatch('partner/getPartnerConfig')
      console.log("partner_config", store.getters.partner_config)
      // console.log("partner_id", store.getters.partner_id)
      // console.log("routes", store.getters.routes)
      const hasRoles = await store.getters.roles!=undefined
      // console.log("hasRoles", hasRoles)
      if (hasRoles == true) {
        await next()
      }
      // else {
        try {
          // get user info
          await store.dispatch('user/getInfo')
          console.log("roles_id", store.getters.roles)
          console.log("partner_id", store.getters.partner_id)
          console.log("routes", store.getters.routes)
          // console.log("push_channel_token", store.getters.push_channel_token=='')
          var payload = { "roles_id": store.getters.roles, "partner_id": store.getters.partner_id }
          // 最高管理權限預設功能
          if (store.getters.partner_id == null && store.getters.roles == 1) {
            const accessRoutes = await store.dispatch('permission/generateRoutes', 1)
            await router.addRoutes(accessRoutes)
            await router.options.routes.push(accessRoutes)
            // 初始化偏好設定
            await store.dispatch('settings/generatesetting')
            console.log("premission.js 65 :",store.getters.theme)
          }
          else{
            const accessRoutes = await store.dispatch('permission/userRoutes', payload)
            await router.addRoutes(accessRoutes)
            await router.options.routes.push(accessRoutes)
            // 初始化偏好設定
            await store.dispatch('settings/generatesetting')
            console.log("premission.js 73 :",store.getters.theme)
            // await next({ ...to, replace: true })
          }
          await next()
          // next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          console.log(error)
          store.dispatch('user/resetToken')
          // Message.error(error || 'Has Error')
          // console.log(`/login?redirect=${to.path}`)
          // next(`/login?redirect=${to.path}`)
          next(`/login?redirect=/dashboard`)
          NProgress.done()
        }
      // }
    }
  } else {
    /* has no token*/
    // console.log("to.path", to.path)
    if (to.path == '/404'){
      next()
    }
    else if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=/dashboard`)
      // next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
