import request from '@/utils/request'


//得知單位管理
export function getUnitList(query) {
  console.log('get_unit_list', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/index_unit_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/index_unit_record/' + query,
      method: 'get'
    })
  }
}

//新增、修改單位管理
export function postUnitList(data) {
  console.log('post_unit_list', typeof (data))
    return request({
      url: '/api/index_unit_record',
      method: 'post',
      data
    })
}

//得知單位管理(右下角超連結)
export function getUnitRelateWebsiteList(query) {
  console.log('get_index_unit_other_url_record', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/index_unit_other_url_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/index_unit_other_url_record/' + query,
      method: 'get'
    })
  }
}

//新增、修改單位管理(右下角超連結)
export function postUnitRelateWebsiteList(data) {
  console.log('index_unit_other_url_record', typeof (data))
    return request({
      url: '/api/index_unit_other_url_record',
      method: 'post',
      data
    })
}

//排序單位管理(右下角超連結)
export function OrderUnitRelateWebsiteList(data) {
  console.log('order_index_unit_other_url_record', typeof (data))
    return request({
      url: '/api/order_index_unit_other_url_record',
      method: 'post',
      data
    })
}

//得知會計管理(設定)
export function getReceptionSetting(query) {
  console.log('get_reception_setting', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/reception_record_setting/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/reception_record_setting/' + query,
      method: 'get'
    })
  }
}

//新增、修改會計管理（設定）
export function postReceptionSetting(data) {
  console.log('post_reception_setting', typeof (data))
    return request({
      url: '/api/insert_update_reception_setting_record',
      method: 'post',
      data
    })
}
