<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="main-article" :class="{'single_div':_count==1}" element-loading-text="正在產生病歷格式">
    <!-- 物理治療評估紀錄表 start -->
    <!-- {{person_List}} -->
    <el-button icon="el-icon-download" type="primary" style="margin:3px 0;" @click="fetchData" id="download_health_btn">匯出病歷</el-button>
    <div v-for="item,index in person_List" :key="index" style="margin:30px 0;" class="single_column_print">
      <div class="article__heading">
        <div class="article__heading__title" style="margin:3px 0;font-size:15px;text-align:center;font-weight: 700;">
          <!-- {{ article.title }} -->
          物理治療評估紀錄表
        </div>
        <div class="article__heading__title" style="margin:3px 0;font-size:15px;text-align:center;font-weight: 700;">
          <!-- {{ article.title }} -->
          Physical Therapy Evaluation Note
        </div>
        <div class="article__heading__title" style="margin-top:3px;margin-bottom:3px;margin-left:10px;font-size:15px;text-align:left;">
          <!-- {{ article.title }} -->
          1、基本資料
        </div>
      </div>
      <div id="first_origin_basic_data">
        <table style="margin:0px 10px;">
          <tbody>
            <tr>
              <td colspan=1 width="100">
                姓名
              </td>
              <td colspan=1 width="150">
                {{item.reserve_system_member_username}}
              </td>
              <td colspan=1 width="50">
                性別
              </td>
              <td colspan=1 width="50">
                {{item.reserve_system_member_sex|reserve_system_member_sex}}
              </td>
              <td colspan=2 width="150">
                出生年月日
              </td>
              <td colspan=2 width="200">
                {{item.reserve_system_member_birthday}}
              </td>
            </tr>
            <tr>
              <td colspan="1" width="100">
                病歷號
              </td>
              <td colspan="3" width="150">
                {{item.reserve_system_health_record_id}}
              </td>
              <td colspan="2" width="150">
                身分證字號
              </td>
              <td colspan="2" width="300">
                {{item.reserve_system_member_identity}}
              </td>
            </tr>
            <tr>
              <td colspan="1" width="100">
                職業
              </td>
              <td colspan="3" width="150">
                {{item.reserve_system_member_career_other}}
              </td>
              <td colspan="2" width="150">
                電話
              </td>
              <td colspan="2" width="300">
                {{item.reserve_system_member_telephone}}
              </td>
            </tr>
            <tr>
              <td colspan="1" width="100">
                地址
              </td>
              <td colspan="7" width="600">
                {{item.reserve_system_member_address}}
              </td>
            </tr>
            <tr>
              <td colspan="1" width="100">
                注意事項
              </td>
              <td colspan="7" width="600">
                {{item.reserve_system_member_note}}
              </td>
            </tr>
            <!-- <tr>
              <td width="500" align="center">
                職業
              </td>
              <td width="300" align="center">
                電話
              </td>
            </tr> -->
            <!-- <tr>
              <td width="300" align="center">
                地址
              </td>
              <td width="300" align="center">
                
              </td>
            </tr> -->
            <!-- <tr>
              <td width="300" align="center">
                注意事項
              </td>
              <td width="300" align="center">
                
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
      <div id="second_three_origin_basic_data" style="margin:10px;">
        <table>
          <tbody>
            <tr>
              <td colspan=1 width="300">
                2、初診/複診：{{item.reserve_system_health_first_record|reserve_system_health_first_record}}
              </td>
              <td colspan=1 width="500">
                3、治療日期： {{item.reserve_system_shop_basic_schedule_start_date}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="four_origin_basic_data" style="margin:10px;">
        <table>
          <tbody>
            <tr>
              <td colspan=1 width="800">
                4、治療部位：{{item.reserve_system_health_person_treat_site}}
              </td>
            </tr>
            <tr v-show="item.reserve_system_health_person_treat_other_site!=''" style="margin:15px 0;">
              <td colspan=1 width="800">
                其他：{{item.reserve_system_health_person_treat_other_site}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="five_origin_basic_data" style="margin:10px;">
        <table>
          <tbody>
            <tr>
              <td colspan=1 width="800">
                5、治療項目：
                <span
                v-show="item.reserve_system_health_person_treat_health_item!=''"
                >
                {{item.reserve_system_health_person_treat_health_item}}
                </span>
                <!-- <el-input v-model="reserve_system_health_person_treat_health_item"
                placeholder="治療項目"
                v-show="reserve_system_health_person_treat_health_item!=''"
                autocomplete="off"
                style="margin:5px 0;"
                disabled
                 /> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="six_origin_basic_data" style="margin:10px;">
        <table>
          <tbody>
            <tr>
              <td colspan=1 width="800">
                6、治療紀錄：
                <span
                v-show="item.reserve_system_health_person_record!=''"
                >
                {{item.reserve_system_health_person_record}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="article__heading">
        <div class="article__heading__title" style="margin-top:3px;margin-bottom:3px;margin-left:10px;font-size:15px;text-align:left;">
          <!-- {{ article.title }} -->
          7、治療照片：
        </div>
      </div>
      <div id="seven_origin_basic_data" class="noBreak" style="margin:10px;">
        <table v-if="item.reserve_system_health_record_photo.length>=1">
          <tbody>
            <tr>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=1">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[0]['reserve_system_health_record_photo']"
                style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=2">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[1]['reserve_system_health_record_photo']"
                style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
            </tr>
            <tr>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=3">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[2]['reserve_system_health_record_photo']"
               style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=4">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[3]['reserve_system_health_record_photo']"
                style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
            </tr>
            <tr>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=5">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[4]['reserve_system_health_record_photo']"
               style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=6">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[5]['reserve_system_health_record_photo']"
                style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
            </tr>
            <tr>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=7">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[6]['reserve_system_health_record_photo']"
                style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
              <td colspan=1 width="400" align="center" v-if="item.reserve_system_health_record_photo.length>=8">
                <el-image 
                :src="upload_path+item.reserve_system_health_record_photo[7]['reserve_system_health_record_photo']"
                style="width:300px;"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="item.reserve_system_health_record_photo.length==0">無治療照片</p>
      </div>
      <div class="article__heading">
        <div class="article__heading__title" style="margin:3px 0;font-size:15px;text-align:right;">
          <!-- {{ article.title }} -->
          治療師:________<u>{{item.edit_reserve_system_health_admin_name}}</u>________ 日期:_____________<u>{{item.edit_reserve_system_health_date==''?item.reserve_system_health_date:item.edit_reserve_system_health_date}}</u>______________
        </div>
      </div>
    </div>
    <!-- 物理治療評估紀錄表 end -->

  </div>
</template>
<style>
#first_origin_basic_data table, #first_origin_basic_data th, #first_origin_basic_data td,
#seven_origin_basic_data table, #seven_origin_basic_data th, #seven_origin_basic_data td
{
  border: 1px solid black;
  border-collapse: collapse;
  border-spacing: 5px;
}
.grecaptcha-badge{ 
    visibility: hidden;
}
@media print {
    #download_health_btn{
      display: none;
    }
    /* 治療照片不換頁 */
    .noBreak{
      page-break-inside: avoid;
    }
    /* 每一欄結束自動換頁 */
    .single_column_print{
      page-break-before: always;
    }
    .single_column_print:first-child{
      page-break-before: avoid;
    }

    .single_body {
      height:100vh;
      margin:0 !important;
      padding:0 !important;
      overflow:hidden !important;
    }
    .single_div {
      contain: strict;
      display: table;
    }
}
</style>
<script>
import { getAppointPersonList } from '@/api/reservationsystemperson'
import moment from 'moment'
export default {
  data() {
    return {
      moment:moment,
      upload_path:'',
      fullscreenLoading: false,
      //報告產生日期
      report_date:'',
      reserve_system_health_person_treat_site:['頸','其他'],
      search_person:'',
      person_List:[],
      _count:0
    }
  },
  filters:{
      reserve_system_member_sex(status){
      const reserve_system_member_sex = {
        0: '男性',
        1: '女性',
      }
      return reserve_system_member_sex[status]
    },
      reserve_system_health_first_record(status){
      const reserve_system_health_first_record = {
        0: '初診',
        1: '複診',
      }
      return reserve_system_health_first_record[status]
    },
  },
  async created() {
    this.fullscreenLoading = true
    this.upload_path = process.env.VUE_APP_BASE_API_UPLOAD
    this.search_person = 'partner_id='+this.$route.params.partner_id+'&chosen_health_id_list='+this.$route.params.chosen_health_id_list
    await this.getAllPerson('all',this.search_person)
  },
  methods: {
      async fetchData() {
      this.fullscreenLoading = true
      import('./content.js').then(async data => {
        // const { title } = data.default
        document.title = '物理治療評估紀錄表'
        this.article = await data.default
        setTimeout(() => {
          this.fullscreenLoading = false
          this.$nextTick(async() => {
            await window.print()
          })
        }, 500)
      })
    },
    async getAllPerson(page, person) {
      var payload = ''
      if (this.search_person == '') {
        payload = page
      }
      if (this.search_person != '') {
        payload += page + '?' + person
      }
      await getAppointPersonList(payload).then(async resp => {
        this.person_List = await resp.data.list
        this._count = await resp.data.count
        if (this._count == 1) {
          document.body.classList.add('single_body')
        }
        this.fullscreenLoading = false
      })
    }
  }
}
</script>