var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "10px 0" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "查看預約人數",
            "before-close": _vm.closeAddAppointReservePerson,
            center: "",
            width: "80%",
            "append-to-body": ""
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "status-icon": "",
                    model: _vm.appointschedule,
                    "label-position": "right"
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服務名稱" } },
                        [
                          _c(
                            "el-tag",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_class ==
                                    0,
                                  expression:
                                    "appointschedule.reserve_system_shop_basic_schedule_class==0"
                                }
                              ],
                              staticStyle: {
                                margin: "10px 0",
                                "font-size": "20px"
                              },
                              attrs: {
                                type:
                                  _vm.appointschedule
                                    .reserve_system_shop_basic_schedule_status ==
                                  0
                                    ? ""
                                    : "danger"
                              }
                            },
                            [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm._f(
                                      "reserve_system_shop_basic_schedule_class"
                                    )(
                                      _vm.appointschedule
                                        .reserve_system_shop_basic_schedule_class
                                    )
                                  ) +
                                  ") " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_teacher
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_class_name
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "el-tag",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_class ==
                                    1,
                                  expression:
                                    "appointschedule.reserve_system_shop_basic_schedule_class==1"
                                }
                              ],
                              staticStyle: {
                                margin: "10px 0",
                                "font-size": "20px"
                              },
                              attrs: {
                                type:
                                  _vm.appointschedule
                                    .reserve_system_shop_basic_schedule_status ==
                                  0
                                    ? ""
                                    : "danger"
                              }
                            },
                            [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm._f(
                                      "reserve_system_shop_basic_schedule_class"
                                    )(
                                      _vm.appointschedule
                                        .reserve_system_shop_basic_schedule_class
                                    )
                                  ) +
                                  ") " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_teacher
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服務日期時段" } },
                        [
                          _c(
                            "el-tag",
                            {
                              staticStyle: {
                                margin: "10px 0",
                                "font-size": "20px"
                              },
                              attrs: {
                                type:
                                  _vm.appointschedule
                                    .reserve_system_shop_basic_schedule_status ==
                                  0
                                    ? ""
                                    : "danger"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_start_date
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_start_time
                                  ) +
                                  " ~ " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_end_date
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_end_time
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "el-tag",
                            {
                              staticStyle: {
                                margin: "10px",
                                "font-size": "20px"
                              },
                              attrs: { type: "danger" }
                            },
                            [
                              _vm._v(
                                "( " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_last_reserve_date
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_last_reserve_time
                                  ) +
                                  " )"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "報名人數" } },
                        [
                          _c(
                            "el-tag",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_limit_switch ==
                                    0,
                                  expression:
                                    "appointschedule.reserve_system_shop_basic_schedule_limit_switch==0"
                                }
                              ],
                              staticStyle: {
                                "font-size": "20px",
                                "text-align": "center"
                              },
                              attrs: {
                                type:
                                  _vm.appointschedule
                                    .reserve_system_shop_basic_schedule_status ==
                                  0
                                    ? ""
                                    : "danger"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_practice_people
                                  ) +
                                  " 人 "
                              )
                            ]
                          ),
                          _c(
                            "el-tag",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_limit_switch ==
                                    1,
                                  expression:
                                    "appointschedule.reserve_system_shop_basic_schedule_limit_switch==1"
                                }
                              ],
                              staticStyle: {
                                "font-size": "20px",
                                "text-align": "center"
                              },
                              attrs: {
                                type:
                                  _vm.appointschedule
                                    .reserve_system_shop_basic_schedule_limit_people >
                                  _vm.appointschedule
                                    .reserve_system_shop_basic_schedule_practice_people
                                    ? ""
                                    : "danger"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_practice_people
                                  ) +
                                  " / " +
                                  _vm._s(
                                    _vm.appointschedule
                                      .reserve_system_shop_basic_schedule_limit_people
                                  ) +
                                  " 人 "
                              )
                            ]
                          ),
                          _c("br")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排程狀態" } },
                        [
                          _c(
                            "el-tag",
                            {
                              staticStyle: {
                                margin: "10px 0",
                                "font-size": "20px"
                              },
                              attrs: {
                                type:
                                  _vm.appointschedule
                                    .reserve_system_shop_basic_schedule_status ==
                                  0
                                    ? ""
                                    : "danger"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f(
                                      "reserve_system_shop_basic_schedule_status"
                                    )(
                                      _vm.appointschedule
                                        .reserve_system_shop_basic_schedule_status
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.orderlistLoading,
                      expression: "orderlistLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.orderList.list,
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "訂單編號" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.reserve_system_order_sn) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "序號" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.reserve_system_order_serial) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "會員資訊" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.reserve_system_member_username)
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.reserve_system_member_telephone
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "250", align: "center", label: "預約備註" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.reserve_system_order_note != null
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        margin: "10px 0",
                                        color: "red"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.reserve_system_order_note
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              : _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "10px 0" } },
                                    [_vm._v(" 無額外需求 ")]
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "預約狀態" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                staticStyle: { margin: "10px 0" },
                                attrs: {
                                  type:
                                    scope.row.reserve_system_order_status == 0
                                      ? "danger"
                                      : ""
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("reserve_system_order_status")(
                                        scope.row.reserve_system_order_status
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "預約時間" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.reserve_system_order_datetime
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "報到狀態" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                staticStyle: { margin: "10px 0" },
                                attrs: {
                                  type:
                                    scope.row.reserve_system_join_status == 0
                                      ? "danger"
                                      : ""
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("reserve_system_join_status")(
                                        scope.row.reserve_system_join_status
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "報到時間" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.reserve_system_join_datetime) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }