<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="main-article" element-loading-text="正在產生收據pdf">
    <!-- 留存收據(管理端) start -->
    <!-- {{reception_List[0]}} -->
    <!-- {{this.search_reception}} -->
    <!-- {{receptionsettingList[0].reserve_system_reception_setting_identify_switch}} -->
    <div>
      <div class="article__heading">
        <div class="article__heading__title" style="margin:3px 0;font-size:24px;text-align:center;">
          <!-- {{ article.title }} -->
          {{unitList[0]['reserve_system_unit_name']}} 醫療費用收據
        </div>
        <div class="article__heading__title" style="color:red;font-size:15px;text-align:right;">
          第一聯 單位留存聯
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:left;" v-if="check_receptionsetting==false">
          <!-- {{ article.title }} -->
          單據序號：{{reception_List[0]['reserve_system_reception_id']}}
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:left;" v-if="check_receptionsetting==true">
          <div v-if="receptionsettingList[0]['reserve_system_reception_setting_reception_id_switch']==1">
          單據序號：{{reception_List[0]['reserve_system_reception_id']}}
          </div>
        </div>
      </div>
      <div class="user-profile">
        <table style="margin:0px 10px;">
          <tbody>
            <tr>
              <td>
                病歷號碼：{{reception_List[0]['reserve_system_health_record_id']}}
              </td>
              <td width="608">
                姓名：{{reception_List[0]['reserve_system_member_username']}}
              </td>
              <td colspan="2">
                身分證字號：{{reception_List[0]['reserve_system_member_identity']}}
              </td>
            </tr>
            <tr>
              <td colspan="1" width="538">
                <strong><strong>費用期間</strong></strong>
              </td>
              <td colspan="3" width="158" align="center">
                {{reception_List[0]['reserve_system_shop_basic_schedule_start_date']}}
              </td>
            </tr>
            <!-- <tr>
              <td width="348">
                <p>費用期間：
                  {{reception_List[0]['reserve_system_shop_basic_schedule_start_time']}} 
                  ~
                  {{reception_List[0]['reserve_system_shop_basic_schedule_end_time']}}
                </p>
              </td>
              <td colspan="3" width="348">
                <p>&nbsp;</p>
              </td>
            </tr> -->
            <tr>
              <td width="500" align="center">
                品項
              </td>
              <td width="300" align="center">
                單價
              </td>
              <td width="300" align="center">
                數量
              </td>
              <td width="300" align="center">
                金額
              </td>
            </tr>
            <tr v-for="(item,index) in reception_List[0]['get_reception_detail']" :key="index">
              <td width="500" align="center">
                {{item.reserve_system_reception_item_name}}
              </td>
              <td width="300" align="center">
                {{item.reserve_system_reception_item_price}}
              </td>
              <td width="300" align="center">
                {{item.reserve_system_reception_item_amount}}
              </td>
              <td width="300" align="center">
                {{item.reserve_system_reception_item_subtotal}}
              </td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>合計金額</strong></strong>
              </td>
              <td width="158" align="center">{{reception_List[0]['reserve_system_amount_payable']}}</td>
            </tr>
            <!-- <tr>
              <td colspan="3" width="538">
                <strong><strong>實繳金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_List[0]['reserve_system_amount_practice']}}
              </td>
            </tr> -->
            <!-- <tr>
              <td colspan="3" width="538">
                <strong><strong>尚欠金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_List[0]['reserve_system_amount_owed']}}
              </td>
            </tr> -->
            <tr>
              <td colspan="2" width="350">
                <p>{{unitList[0]['reserve_system_unit_name']}}</p>
                <p>地址：{{unitList[0]['reserve_system_unit_tax']}}</p>
                <p>電話：{{unitList[0]['reserve_system_unit_phone']}}</p>
                <p v-if="check_receptionsetting==false">院代號碼：</p>
                <p v-if="check_receptionsetting==true">院代號碼：{{receptionsettingList[0]['reserve_system_reception_setting_hospital_number']}}</p>
                <p>收訖章：</p>
              </td>
              <td colspan="2" width="350">
                <p v-if="check_receptionsetting==false">印花總繳印章：</p>
                <p v-if="check_receptionsetting==true">印花總繳印章：
                  <el-image 
                  :src="upload_path+receptionsettingList[0]['reserve_system_reception_setting_photo']"
                  v-if="receptionsettingList[0]['reserve_system_reception_setting_photo']!=null"
                  style="width:130px;"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" />
                    </div>
                  </el-image>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :lg="12" :xl="12" style="margin:10px 0;color:red;">
            本憑證請妥為保管，遺失恕不補發
          </el-col>
          <el-col :xs="12" :sm="12" :lg="12" :xl="12" style="margin:10px 0;color:red;text-align:right;">
            第一聯 單位留存聯
          </el-col>
      </el-row> -->
      <div style="margin:3px;color:red;">
        本憑證請妥為保管，遺失恕不補發
      </div>
    </div>
    <br/>
    <br/>
    <!-- 留存收據(管理端) end -->
    <!-- 留存收據(客戶端) start -->
    <div style="margin:5px 0;">
      <div class="article__heading">
        <div class="article__heading__title" style="margin:3px 0;font-size:24px;text-align:center;">
          <!-- {{ article.title }} -->
          {{unitList[0]['reserve_system_unit_name']}} 醫療費用收據
        </div>
        <div class="article__heading__title" style="margin:3px 0;font-size:15px;text-align:center;"> </div>
        <div class="article__heading__title" style="color:red;font-size:15px;text-align:right;">
          <!-- {{ article.title }} -->
          第二聯 客戶留存聯
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:left;" v-if="check_receptionsetting==false">
          <!-- {{ article.title }} -->
          單據序號：{{reception_List[0]['reserve_system_reception_id']}}
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:left;" v-if="check_receptionsetting==true">
          <!-- {{ article.title }} -->
          <div v-if="receptionsettingList[0]['reserve_system_reception_setting_reception_id_switch']==1">
          單據序號：{{reception_List[0]['reserve_system_reception_id']}}
          </div>
        </div>
      </div>
      <div class="user-profile">
        <table style="margin:0px 10px;">
          <tbody>
            <tr>
              <td>
                病歷號碼：{{reception_List[0]['reserve_system_health_record_id']}}
              </td>
              <td width="608">
                姓名：{{reception_List[0]['reserve_system_member_username']}}
              </td>
              <td colspan="2">
                身分證字號：{{reception_List[0]['reserve_system_member_identity']}}
              </td>
            </tr>
            <tr>
              <td colspan="1" width="538">
                <strong><strong>費用期間</strong></strong>
              </td>
              <td colspan="3" width="158" align="center">
                {{reception_List[0]['reserve_system_shop_basic_schedule_start_date']}}
              </td>
            </tr>
            <tr>
              <td width="500" align="center">
                品項
              </td>
              <td width="300" align="center">
                單價
              </td>
              <td width="300" align="center">
                數量
              </td>
              <td width="300" align="center">
                金額
              </td>
            </tr>
            <tr v-for="(item,index) in reception_List[0]['get_reception_detail']" :key="index">
              <td width="500" align="center">
                {{item.reserve_system_reception_item_name}}
              </td>
              <td width="300" align="center">
                {{item.reserve_system_reception_item_price}}
              </td>
              <td width="300" align="center">
                {{item.reserve_system_reception_item_amount}}
              </td>
              <td width="300" align="center">
                {{item.reserve_system_reception_item_subtotal}}
              </td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>合計金額</strong></strong>
              </td>
              <td width="158" align="center">{{reception_List[0]['reserve_system_amount_payable']}}</td>
            </tr>
            <!-- <tr>
              <td colspan="3" width="538">
                <strong><strong>實繳金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_List[0]['reserve_system_amount_practice']}}
              </td>
            </tr> -->
            <!-- <tr>
              <td colspan="3" width="538">
                <strong><strong>尚欠金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_List[0]['reserve_system_amount_owed']}}
              </td>
            </tr> -->
            <tr>
              <td colspan="2" width="350">
                <p>{{unitList[0]['reserve_system_unit_name']}}</p>
                <p>地址：{{unitList[0]['reserve_system_unit_tax']}}</p>
                <p>電話：{{unitList[0]['reserve_system_unit_phone']}}</p>
                <p v-if="check_receptionsetting==false">院代號碼：</p>
                <p v-if="check_receptionsetting==true">院代號碼：{{receptionsettingList[0]['reserve_system_reception_setting_hospital_number']}}</p>
                <p>收訖章：</p>
              </td>
              <td colspan="2" width="350">
                <p v-if="check_receptionsetting==false">印花總繳印章：</p>
                <p v-if="check_receptionsetting==true">印花總繳印章：
                  <el-image 
                  :src="upload_path+receptionsettingList[0]['reserve_system_reception_setting_photo']"
                  v-if="receptionsettingList[0]['reserve_system_reception_setting_photo']!=null"
                  style="width:130px;"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" />
                    </div>
                  </el-image>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="margin:3px;color:red;">
        本憑證請妥為保管，遺失恕不補發
      </div>
    </div>
    <!-- 留存收據(客戶端) end -->

  </div>
</template>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  border-spacing: 5px;
}
.grecaptcha-badge{ 
    visibility: hidden;
}
@media print {
    html, body {
        height: 99%;    
    }
}
</style>
<script>
import moment from 'moment'
import { getAppointReceptionList } from '@/api/reservationsystemreception'
import { getReceptionSetting } from '@/api/reservationsystemunit'
import { getUnitList } from '@/api/reservationsystemunit'
const defaultReceptionSetting = {
  partner_id:'',
  reserve_system_reception_setting_identify_switch:0,
}
export default {
  data() {
    return {
      upload_path:'',
      fullscreenLoading: true,
      //報告產生日期
      report_date:'',
      search_reception:'',
      search_reception_setting:'',
      search_unit:'',
      reception_List:[],
      //會計設定模組
      receptionsetting: Object.assign({}, defaultReceptionSetting),
      receptionsettingList:[],
      check_receptionsetting:false,
      unitList:[],
      //得到實際多少切割字元
      get_practice_split_identity:0
    }
  },
  async created() {
    // 圖片網址位置
    this.upload_path = process.env.VUE_APP_BASE_API_UPLOAD
    this.report_date = moment(new Date()).format('YYYY/MM/DD')
    console.log("this.$route.params.reserve_system_order",this.$route.params.reserve_system_order)
    this.search_reception = 'partner_id='+this.$route.params.partner_id+'&reserve_system_order='+this.$route.params.reserve_system_order
    this.search_unit = 'partner_id='+this.$route.params.partner_id
    this.search_reception_setting = 'partner_id='+this.$route.params.partner_id
    await this.getAllUnit(1,this.search_unit)
    await this.getAllReceptionSetting(1, this.search_reception_setting)
    await this.getAllReception(1, this.search_reception)
    await this.fetchData()
  },
  methods: {
      fetchData() {
      import('./content.js').then(data => {
        // const { title } = data.default
        document.title = this.reception_List[0]['reserve_system_member_username'] +'留存收據 '+
        "("+this.reception_List[0]['reserve_system_reception_id']+")"
        this.article = data.default
        setTimeout(() => {
          this.fullscreenLoading = false
          this.$nextTick(() => {
            window.print()
          })
        }, 500)
      })
    },
    async getAllReception(page, reception) {
      var payload = ''
      if (this.search_reception == '') {
        payload = page
      }
      if (this.search_reception != '') {
        payload += page + '?' + reception
      }
      await getAppointReceptionList(payload).then(async resp => {
        this.reception_List = await resp.data.list
      })
      await this.setting_reception_shop_identity()
    },
    async setting_reception_shop_identity(){
      //判定身分證後4碼未顯示且資料為10字元才進行判定
      if(this.reception_List[0]['reserve_system_member_identity']==null){
        this.reception_List[0]['reserve_system_member_identity'] = this.reception_List[0]['reserve_system_member_identity']
      }
      //不足五字元直接顯示
      else if(this.reception_List[0]['reserve_system_member_identity'].length<5){
        this.reception_List[0]['reserve_system_member_identity'] = this.reception_List[0]['reserve_system_member_identity']
      }
      else if(this.receptionsettingList[0].reserve_system_reception_setting_identify_switch==0&&this.reception_List[0]['reserve_system_member_identity'].length>=5){
        this.get_practice_split_identity = parseInt(this.reception_List[0]['reserve_system_member_identity'].length)-4
        this.reception_List[0]['reserve_system_member_identity'] = this.reception_List[0]['reserve_system_member_identity'].substring(0,this.get_practice_split_identity)+'****'
      }
    },
    async getAllUnit(page, unit) {
      var payload = ''
      var _self = this
      if ( _self.search_unit == '') {
        payload = page
      }
      if ( _self.search_unit != '') {
        payload += page + '?' + unit
      }
      await getUnitList(payload).then(async resp => {
        _self.unitList = await resp.data.list
      })
    },
    async getAllReceptionSetting(page, reception_setting) {
      var payload = ''
      var _self = this
      defaultReceptionSetting['partner_id'] = _self.$route.params.partner_id
      payload += page + '?' + reception_setting
      await getReceptionSetting(payload).then(async resp => {
         _self.receptionsettingList = []
         //當資料為空的呈現預設的資料
        if(resp.data.count==0){
          //是否無設定會計模組
          _self.check_receptionsetting = false
          _self.receptionsettingList.partner_id= _self.$route.params.partner_id
          await _self.receptionsettingList.push( _self.receptionsetting)
        }
        //當資料不為空的呈現設定資料
        else{
            _self.receptionsettingList = await resp.data.list
             //是否有設定會計模組
            _self.check_receptionsetting = true
        }
      })
    },
  }
}
</script>