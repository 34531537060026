import request from '@/utils/request'

//得知個案紀錄查詢
export function getAppointReserveList(query) {
  console.log('check_appoint_Person_record', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/appoint_join_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/appoint_join_record/' + query,
      method: 'get'
    })
  }
}

//得知病歷管理
export function getAppointPersonList(query) {
  console.log('get_appoint_Person_record', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/health_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/health_record/' + query,
      method: 'get'
    })
  }
}

//修改病歷管理
export function postAppointPersonList(data) {
  console.log('appoint_Person_record_list', typeof (data))
    return request({
      url: '/api/update_health_record',
      method: 'post',
      data
    })
}

//得知歷史病歷管理
export function getAppointPastPersonList(query) {
  console.log('get_appoint_Person_past_record', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/health_past_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/health_past_record/' + query,
      method: 'get'
    })
  }
}