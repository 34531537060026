const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  email: state => state.user.email,
  validator_mode: state => state.user.validator_mode,
  validator_mode_status: state => state.user.validator_mode_status,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  roles_name: state => state.user.roles_name,
  theme: state=> state.settings.theme,
  // 使用者_id
  id: state => state.user.id,
  // 使用者_狀態
  userstatus: state => state.user.status,
  // 合作夥伴圖片
  partner_avatar: state => state.user.partner_avatar,
  // 特休
  special_day: state => state.user.special_day,
  username: state => state.user.username,
  // 合作夥伴標題
  partner_name: state => state.user.partner_name,
  // line notify 推播
  push_channel_token: state => state.user.push_channel_token,
  phone: state => state.user.phone,
  partner_id: state => state.user.partner_id,
  routes: state => state.permission.routes,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  partnerlist: state => state.partner.partnerlist,
  partnercategorylist: state => state.partner.partnercategorylist,
  partner_config: state => state.partner.partner_config,
  questionlist: state => state.question.questionlist,
  questioncategorylist: state => state.question.questioncategorylist,
  hotnewslist: state => state.hotnews.hotnewslist,
  hotnewscategorylist: state => state.hotnews.hotnewscategorylist,
  //服務項目
  servicelist: state => state.reservationsystemappointservice.servicelist,
  //服務項目分類
  servicecategorylist: state => state.reservationsystemappointservice.servicecategorylist,
  //衛教
  healthlist: state => state.reservationsystemappointhealth.healthlist,
  //衛教分類
  healthcategorylist: state => state.reservationsystemappointhealth.healthcategorylist,
  //常用子句
  wordcategorylist: state => state.reservationsystemword.wordcategorylist,
  adminroleslist: state => state.admin.roleslist,
  todolist: state => state.todolist.todolist,
  pushchannellist: state => state.push_channel.pushchannellist,
  pushchannelgrouplist: state => state.push_channel.pushchannelgrouplist,
  contractlist: state => state.contract.contractlist,
  // memberlist: state =>state.member.memberlist,
  shopcategorylist: state => state.shop.shopcategorylist,
  orderlist: state => state.shop.orderlist,
  allmemberlist: state => state.shop.allmemberlist,
  routerfatherlist: state => state.router_manager.routerfatherlist,
  routersonlist: state => state.router_manager.routersonlist,
  // 得知目前合作夥伴角色
  premissionroleslist: state => state.premission.premissionroleslist,
  // 得知目前合作夥伴權限
  premissionlist: state => state.premission.premissionlist,
  // 得知貼圖主分類
  mainpackagelist: state => state.linemainpackage.premissionlist,
  // 得知貼圖子分類
  subpackagelist: state => state.linesubpackage.premissionlist,
  driverManagerList: state => state.driver_router_manager.driverManagerList,
  //即時更新聊天狀況
  check_chat_auto_update: state => state.shop.check_chat_auto_update,
}
export default getters
