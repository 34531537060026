var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "正在產生收據pdf" }
    },
    [
      _c("div", [
        _c("div", { staticClass: "article__heading" }, [
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "3px 0",
                "font-size": "24px",
                "text-align": "center"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.unitList[0]["reserve_system_unit_name"]) +
                  " 醫療費用收據 "
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                color: "red",
                "font-size": "15px",
                "text-align": "right"
              }
            },
            [_vm._v(" 第一聯 單位留存聯 ")]
          ),
          _vm.check_receptionsetting == false
            ? _c(
                "div",
                {
                  staticClass: "article__heading__title",
                  staticStyle: {
                    margin: "10px 0",
                    "font-size": "15px",
                    "text-align": "left"
                  }
                },
                [
                  _vm._v(
                    " 單據序號：" +
                      _vm._s(
                        _vm.reception_List[0]["reserve_system_reception_id"]
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.check_receptionsetting == true
            ? _c(
                "div",
                {
                  staticClass: "article__heading__title",
                  staticStyle: {
                    margin: "10px 0",
                    "font-size": "15px",
                    "text-align": "left"
                  }
                },
                [
                  _vm.receptionsettingList[0][
                    "reserve_system_reception_setting_reception_id_switch"
                  ] == 1
                    ? _c("div", [
                        _vm._v(
                          " 單據序號：" +
                            _vm._s(
                              _vm.reception_List[0][
                                "reserve_system_reception_id"
                              ]
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "user-profile" }, [
          _c("table", { staticStyle: { margin: "0px 10px" } }, [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      " 病歷號碼：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_health_record_id"
                          ]
                        ) +
                        " "
                    )
                  ]),
                  _c("td", { attrs: { width: "608" } }, [
                    _vm._v(
                      " 姓名：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_username"
                          ]
                        ) +
                        " "
                    )
                  ]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      " 身分證字號：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_identity"
                          ]
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("tr", [
                  _vm._m(0),
                  _c(
                    "td",
                    { attrs: { colspan: "3", width: "158", align: "center" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.reception_List[0][
                              "reserve_system_shop_basic_schedule_start_date"
                            ]
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _vm._m(1),
                _vm._l(_vm.reception_List[0]["get_reception_detail"], function(
                  item,
                  index
                ) {
                  return _c("tr", { key: index }, [
                    _c("td", { attrs: { width: "500", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_name) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { width: "300", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_price) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { width: "300", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_amount) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { width: "300", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_subtotal) +
                          " "
                      )
                    ])
                  ])
                }),
                _c("tr", [
                  _vm._m(2),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.reception_List[0]["reserve_system_amount_payable"]
                      )
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2", width: "350" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.unitList[0]["reserve_system_unit_name"])
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_tax"])
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "電話：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_phone"])
                      )
                    ]),
                    _vm.check_receptionsetting == false
                      ? _c("p", [_vm._v("院代號碼：")])
                      : _vm._e(),
                    _vm.check_receptionsetting == true
                      ? _c("p", [
                          _vm._v(
                            "院代號碼：" +
                              _vm._s(
                                _vm.receptionsettingList[0][
                                  "reserve_system_reception_setting_hospital_number"
                                ]
                              )
                          )
                        ])
                      : _vm._e(),
                    _c("p", [_vm._v("收訖章：")])
                  ]),
                  _c("td", { attrs: { colspan: "2", width: "350" } }, [
                    _vm.check_receptionsetting == false
                      ? _c("p", [_vm._v("印花總繳印章：")])
                      : _vm._e(),
                    _vm.check_receptionsetting == true
                      ? _c(
                          "p",
                          [
                            _vm._v("印花總繳印章： "),
                            _vm.receptionsettingList[0][
                              "reserve_system_reception_setting_photo"
                            ] != null
                              ? _c(
                                  "el-image",
                                  {
                                    staticStyle: { width: "130px" },
                                    attrs: {
                                      src:
                                        _vm.upload_path +
                                        _vm.receptionsettingList[0][
                                          "reserve_system_reception_setting_photo"
                                        ]
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture-outline"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              ],
              2
            )
          ])
        ]),
        _c("div", { staticStyle: { margin: "3px", color: "red" } }, [
          _vm._v(" 本憑證請妥為保管，遺失恕不補發 ")
        ])
      ]),
      _c("br"),
      _c("br"),
      _c("div", { staticStyle: { margin: "5px 0" } }, [
        _c("div", { staticClass: "article__heading" }, [
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                margin: "3px 0",
                "font-size": "24px",
                "text-align": "center"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.unitList[0]["reserve_system_unit_name"]) +
                  " 醫療費用收據 "
              )
            ]
          ),
          _c("div", {
            staticClass: "article__heading__title",
            staticStyle: {
              margin: "3px 0",
              "font-size": "15px",
              "text-align": "center"
            }
          }),
          _c(
            "div",
            {
              staticClass: "article__heading__title",
              staticStyle: {
                color: "red",
                "font-size": "15px",
                "text-align": "right"
              }
            },
            [_vm._v(" 第二聯 客戶留存聯 ")]
          ),
          _vm.check_receptionsetting == false
            ? _c(
                "div",
                {
                  staticClass: "article__heading__title",
                  staticStyle: {
                    margin: "10px 0",
                    "font-size": "15px",
                    "text-align": "left"
                  }
                },
                [
                  _vm._v(
                    " 單據序號：" +
                      _vm._s(
                        _vm.reception_List[0]["reserve_system_reception_id"]
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.check_receptionsetting == true
            ? _c(
                "div",
                {
                  staticClass: "article__heading__title",
                  staticStyle: {
                    margin: "10px 0",
                    "font-size": "15px",
                    "text-align": "left"
                  }
                },
                [
                  _vm.receptionsettingList[0][
                    "reserve_system_reception_setting_reception_id_switch"
                  ] == 1
                    ? _c("div", [
                        _vm._v(
                          " 單據序號：" +
                            _vm._s(
                              _vm.reception_List[0][
                                "reserve_system_reception_id"
                              ]
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "user-profile" }, [
          _c("table", { staticStyle: { margin: "0px 10px" } }, [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      " 病歷號碼：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_health_record_id"
                          ]
                        ) +
                        " "
                    )
                  ]),
                  _c("td", { attrs: { width: "608" } }, [
                    _vm._v(
                      " 姓名：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_username"
                          ]
                        ) +
                        " "
                    )
                  ]),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      " 身分證字號：" +
                        _vm._s(
                          _vm.reception_List[0][
                            "reserve_system_member_identity"
                          ]
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("tr", [
                  _vm._m(3),
                  _c(
                    "td",
                    { attrs: { colspan: "3", width: "158", align: "center" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.reception_List[0][
                              "reserve_system_shop_basic_schedule_start_date"
                            ]
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _vm._m(4),
                _vm._l(_vm.reception_List[0]["get_reception_detail"], function(
                  item,
                  index
                ) {
                  return _c("tr", { key: index }, [
                    _c("td", { attrs: { width: "500", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_name) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { width: "300", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_price) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { width: "300", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_amount) +
                          " "
                      )
                    ]),
                    _c("td", { attrs: { width: "300", align: "center" } }, [
                      _vm._v(
                        " " +
                          _vm._s(item.reserve_system_reception_item_subtotal) +
                          " "
                      )
                    ])
                  ])
                }),
                _c("tr", [
                  _vm._m(5),
                  _c("td", { attrs: { width: "158", align: "center" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.reception_List[0]["reserve_system_amount_payable"]
                      )
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", { attrs: { colspan: "2", width: "350" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.unitList[0]["reserve_system_unit_name"])
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_tax"])
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "電話：" +
                          _vm._s(_vm.unitList[0]["reserve_system_unit_phone"])
                      )
                    ]),
                    _vm.check_receptionsetting == false
                      ? _c("p", [_vm._v("院代號碼：")])
                      : _vm._e(),
                    _vm.check_receptionsetting == true
                      ? _c("p", [
                          _vm._v(
                            "院代號碼：" +
                              _vm._s(
                                _vm.receptionsettingList[0][
                                  "reserve_system_reception_setting_hospital_number"
                                ]
                              )
                          )
                        ])
                      : _vm._e(),
                    _c("p", [_vm._v("收訖章：")])
                  ]),
                  _c("td", { attrs: { colspan: "2", width: "350" } }, [
                    _vm.check_receptionsetting == false
                      ? _c("p", [_vm._v("印花總繳印章：")])
                      : _vm._e(),
                    _vm.check_receptionsetting == true
                      ? _c(
                          "p",
                          [
                            _vm._v("印花總繳印章： "),
                            _vm.receptionsettingList[0][
                              "reserve_system_reception_setting_photo"
                            ] != null
                              ? _c(
                                  "el-image",
                                  {
                                    staticStyle: { width: "130px" },
                                    attrs: {
                                      src:
                                        _vm.upload_path +
                                        _vm.receptionsettingList[0][
                                          "reserve_system_reception_setting_photo"
                                        ]
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture-outline"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              ],
              2
            )
          ])
        ]),
        _c("div", { staticStyle: { margin: "3px", color: "red" } }, [
          _vm._v(" 本憑證請妥為保管，遺失恕不補發 ")
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "1", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("費用期間")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { width: "500", align: "center" } }, [
        _vm._v(" 品項 ")
      ]),
      _c("td", { attrs: { width: "300", align: "center" } }, [
        _vm._v(" 單價 ")
      ]),
      _c("td", { attrs: { width: "300", align: "center" } }, [
        _vm._v(" 數量 ")
      ]),
      _c("td", { attrs: { width: "300", align: "center" } }, [_vm._v(" 金額 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("合計金額")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "1", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("費用期間")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { width: "500", align: "center" } }, [
        _vm._v(" 品項 ")
      ]),
      _c("td", { attrs: { width: "300", align: "center" } }, [
        _vm._v(" 單價 ")
      ]),
      _c("td", { attrs: { width: "300", align: "center" } }, [
        _vm._v(" 數量 ")
      ]),
      _c("td", { attrs: { width: "300", align: "center" } }, [_vm._v(" 金額 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "3", width: "538" } }, [
      _c("strong", [_c("strong", [_vm._v("合計金額")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }